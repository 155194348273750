import Box from '@mui/material/Box';
import SideMenuContent from './components/side-menu-content';
import Drawer from '../../core-ui/drawer/drawer.component';

interface SideMenuProps {
  mobileOpen: boolean;
  setMobileOpen: (mobileOpen: boolean) => void;
}
const SideMenu = ({ mobileOpen, setMobileOpen }: SideMenuProps) => {
  const drawerWidth = 256;
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box
      component="nav"
      sx={{
        width: { xs: 0, md: drawerWidth },
        flexShrink: { xs: 0 }
      }}
      aria-label="SideMenu"
    >
      <Drawer
        config={{ width: drawerWidth, anchor: 'left', isOpen: mobileOpen, appearOverAppbar: false }}
        eventHandlers={{ handleClose: handleDrawerToggle }}
      >
        <SideMenuContent />
      </Drawer>
      <Drawer
        config={{
          width: drawerWidth,
          variant: 'permanent',
          anchor: 'left',
          appearOverAppbar: false
        }}
        styles={{ display: { xs: 'none', md: 'flex' } }}
      >
        <SideMenuContent />
      </Drawer>
    </Box>
  );
};

export default SideMenu;
