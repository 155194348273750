import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const StyledTipsButton = styled(Button)<ButtonProps>(({ theme }) => ({
  border: `1.4px solid ${theme.palette.grey[100]}`,
  borderRadius: '1.4rem',
  padding: '1.04rem',
  color: theme.palette.text.primary,
  lineHeight: 0,
  gap: '1rem',
  '& .MuiButton-startIcon': {
    margin: 0
  }
}));

export default StyledTipsButton;
