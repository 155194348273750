import React, { Children } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Outlet, Router } from 'react-router';
import { BrowserRouter, Route, Navigate, Routes, useRoutes } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { createBrowserHistory } from 'history';
import { PUBLIC_PATH, APP_ROUTES } from './shared/config/routes-config';
import ReduxStore from './shared/redux/store';
import { ReactNotifications } from 'react-notifications-component';
import { initializeTrackingTools } from './shared/services/utils/tracking/tracking.service';
import { initializeI18n } from './shared/config/i18n.config';
import { AuthRoutesConfig } from './Modules/AuthModule/shared/config/auth-routes.config';
import { DashboardRoutesConfig } from './DashboardModule/shared/config/dashboard-routes.config';
import { refreshUserSession } from './shared/services/data/user-data/auth.data';
import { AuthStatus } from './shared/enums/auth.status.enum';
import { RouteConfig } from './shared/interfaces/routes-config.interface';
import './index.scss';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import 'react-notifications-component/dist/theme.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CustomTheme from './shared/themes';
import { MerchantRoutesConfig } from './Modules/MerchatsModule/shared/config/merchant-routes.config';
import { ProductsRoutesConfig } from './Modules/ProductsModule/AutoProductsModule/shared/config/products-routes.config';
import { FinancialProductsRoutesConfig } from './Modules/ProductsModule/FinancialProductsModule/shared/config/financial-products-routes.config';
import { CustomersRoutesConfig } from './Modules/CustomersModule/shared/config/customers-routes.config';
import { FinanceApplicationsRoutesConfig } from './Modules/FinanceApplicationsModule/shared/config/finance-applications-routes.config';
import { LeadsRoutesConfig } from './Modules/LeadsModule/shared/config/leads-routes.config';
import { TransactionsRoutesConfig } from './Modules/TransactionsModule/shared/config/transaction-routes.config';
import { PurchasesRoutesConfig } from './Modules/PurchaseOrdersModule/shared/config/purchase-routes.config';
import { ReportsRoutesConfig } from './Modules/ReportsModule/shared/config/finance-applications-reports-routes.config';
import { UsersManagementRoutesConfig } from './Modules/UserManagement/shared/config/user-management-routes.config';
import { SalesManagementRoutesConfig } from './Modules/SalesManagementModule/shared/config/sales-management-routes.config';
import { CommunicationRoutesConfig } from './Modules/CommunicationModule/shared/config/communication-routes.config';

// import app modules

const store = ReduxStore;
initializeTrackingTools();
initializeI18n();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

refreshUserSession().then((response) => {
  if (
    response === AuthStatus.UNAUTHORIZED &&
    window.location.href.indexOf(
      (APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).UNAUTHORIZED.FULL_PATH
    ) < 0
  ) {
    console.log('User not premitted to access admin page');
    window.location.href =
      PUBLIC_PATH + (APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).UNAUTHORIZED.FULL_PATH;
    // setTimeout(() => {
    //   history.push((APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).UNAUTHORIZED.FULL_PATH);
    // }, 500);
  }
});
const App = () => {
  console.log('Configure app routes');
  const routes = useRoutes([
    {
      index: true,
      element: <Navigate to={APP_ROUTES.AUTH_MODULE.FULL_PATH} />
    },
    {
      path: APP_ROUTES.AUTH_MODULE.FULL_PATH,
      children: AuthRoutesConfig
    },
    {
      path: APP_ROUTES.DASHBOARD_MODULE.FULL_PATH,
      children: DashboardRoutesConfig
    },
    {
      path: APP_ROUTES.COMMUNICATION_MODULE.FULL_PATH,
      children: CommunicationRoutesConfig
    },
    {
      path: APP_ROUTES.MERCHANT_MODULE.FULL_PATH,
      children: MerchantRoutesConfig
    },
    {
      path: APP_ROUTES.PRODUCTS_MODULE.FULL_PATH,
      children: ProductsRoutesConfig
    },
    {
      path: APP_ROUTES.FINANCIAL_PRODUCTS_MODULE.FULL_PATH,
      children: FinancialProductsRoutesConfig
    },
    {
      path: APP_ROUTES.CUSTOMERS_MODULE.FULL_PATH,
      children: CustomersRoutesConfig
    },
    {
      path: APP_ROUTES.FINANCE_APPLICATIONS_MODULE.FULL_PATH,
      children: FinanceApplicationsRoutesConfig
    },
    {
      path: APP_ROUTES.LEADS_MODULE.FULL_PATH,
      children: LeadsRoutesConfig
    },
    {
      path: APP_ROUTES.TRANSACTIONS_MODULE.FULL_PATH,
      children: TransactionsRoutesConfig
    },
    {
      path: APP_ROUTES.PURCHASES_MODULE.FULL_PATH,
      children: PurchasesRoutesConfig
    },
    {
      path: APP_ROUTES.REPORTS_MODULE.FULL_PATH,
      children: ReportsRoutesConfig
    },
    {
      path: APP_ROUTES.USER_MANAGEMENT_MODULE.FULL_PATH,
      children: UsersManagementRoutesConfig
    },
    {
      path: APP_ROUTES.SALES_MANAGEMENT_MODULE.FULL_PATH,
      children: SalesManagementRoutesConfig
    }
  ]);
  return routes;
};

root.render(
  <React.StrictMode>
    <ReactNotifications />
    <Provider store={store}>
      <BrowserRouter basename={PUBLIC_PATH || '/'}>
        <CustomTheme>
          <App />
        </CustomTheme>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
