import MuiChip, { ChipProps } from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { ChipColor } from './chip.component';

export interface StyledChipProps extends ChipProps {
  chipColor: ChipColor;
  isDisabled?: boolean;
  paddingTop?: string;
  chipHeight?: string;
  chipLineHeight?: string;
  chipFont?: string;
  chipMarginBottom?: string;
}

const StyledChip = styled(MuiChip, {
  shouldForwardProp: (prop) =>
    prop !== 'isDisabled' &&
    prop !== 'chipColor' &&
    prop !== 'paddingTop' &&
    prop !== 'chipHeight' &&
    prop !== 'chipLineHeight' &&
    prop !== 'chipFont' &&
    prop !== 'chipMarginBottom'
})<StyledChipProps>(
  ({
    isDisabled,
    chipColor,
    paddingTop = '4px',
    chipHeight = '24px',
    chipLineHeight = '1.6rem',
    chipFont = '1.2rem',
    chipMarginBottom = 0,
    theme
  }) => {
    const chipLabelFonts = { fontWeight: 800, fontSize: chipFont, lineHeight: chipLineHeight };

    return {
      height: chipHeight,
      marginRight: '8px',
      marginBottom: chipMarginBottom,
      backgroundColor: isDisabled ? theme.palette.grey[100] : theme.palette[chipColor].light,
      '& .MuiChip-label': {
        padding: `${paddingTop} 12px`,
        color: isDisabled ? theme.palette.grey[400] : theme.palette[chipColor].dark
      },
      '& .MuiChip-deleteIcon': {
        color: theme.palette.text.primary,
        width: '18px',
        height: '18px',
        margin: '0 8px 0 -8px',
        '&:hover': {
          color: theme.palette.error.main
        }
      },
      h5: {
        ...chipLabelFonts,
        color: isDisabled ? theme.palette.grey[400] : theme.palette[chipColor].dark
      },
      h6: {
        ...chipLabelFonts,
        color: theme.palette.primary.main,
        marginLeft: '4px'
      }
    };
  }
);

export { StyledChip };
