import {
  GetMerchantDocumentsModel,
  MerchantDocsModel,
  MerchantDocumentsDataModel
} from '../../models/merchant.model';
import {
  MerchantDocsUIModel,
  MerchantDocumentsDataUIModel
} from '../../ui-models/merchants.ui-model';

const MerchantDocumentTypes = {
  commercial_registration: 'commercial_registration',
  tax_id: 'tax_id',
  vat_certificate: 'vat_certificate',
  withholding_tax_certificate: 'withholding_tax_certificate'
};

function mapToModel(docs: MerchantDocumentsDataUIModel): MerchantDocumentsDataModel {
  const mapArrayProperty = (property: keyof MerchantDocsUIModel) => {
    return docs[property]?.map((file) => file.file) ?? [];
  };

  const result: MerchantDocumentsDataModel = {
    companyId: docs.companyId ?? ''
  };

  for (const [dataProperty, uiProperty] of Object.entries(MerchantDocumentTypes) as [
    keyof MerchantDocsUIModel,
    keyof MerchantDocsUIModel
  ][]) {
    if (docs[dataProperty]?.length) {
      result[uiProperty] = mapArrayProperty(dataProperty);
    }
  }

  return result;
}

function mapToUI(docs: GetMerchantDocumentsModel[]): MerchantDocumentsDataUIModel {
  const result: MerchantDocsUIModel = {};

  for (const doc of docs) {
    const type = MerchantDocumentTypes[
      doc.documentType as keyof MerchantDocsModel
    ] as keyof MerchantDocsModel;
    if (type) {
      if (!result[type]) {
        result[type] = [];
      }
      result[type]?.push({
        dataURL: doc.url,
        file: { name: doc.key?.split('/')[1] },
        id: doc.key,
        key: doc.key,
        documentType: doc.documentType
      });
    }
  }

  return result;
}

const merchantDocsMapper = { mapToModel, mapToUI };
export default merchantDocsMapper;
