import { FC, Fragment } from 'react';

import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Skeleton from '@mui/material/Skeleton';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Chip, { ChipProps } from '../../core-ui/chip/chip.component';
import { CustomButtonProps } from '../../core-ui/button/button.component';
import { ReactComponent as BackIcon } from '../../../../assets/svgs/sub-header-back.icon.svg';
import {
  StyledButton,
  StyledContainer,
  StyledMainContainer,
  StyledSkeleton,
  StyledTab,
  StyledTabs,
  StyledTabsContainer
} from './page-header.styles';

interface PageHeaderProps {
  data: {
    title: string;
    description?: string;
    loading?: boolean;
    selectedTab?: string | boolean;
    anchorEl?: null | HTMLElement;
    buttonMenuItems?: ButtonMenuItem[];
    tabsLoading?: boolean;
  };
  config?: { hasBackNavigation?: boolean; colleace?: boolean };
  components?: {
    chipList?: ChipProps[];
    actionsList: CustomButtonProps[];
    tabs?: {
      value: string;
      label: string;
      disabled?: boolean;
    }[];
  };
  eventHandlers?: {
    handleBackNavigation?: () => void;
    setSelectedTab?: (value: string) => void;
    setAnchorEl?: (anchorEl: null | HTMLElement) => void;
  };
}

export interface ButtonMenuItem {
  name: string;
  action: () => void;
}

const PageHeader: FC<PageHeaderProps> = ({ data, config = {}, components, eventHandlers }) => {
  const {
    title,
    description,
    loading,
    selectedTab,
    anchorEl,
    buttonMenuItems,
    tabsLoading = false
  } = data;
  const { hasBackNavigation, colleace = false } = config;
  const { chipList, actionsList, tabs } = components ?? { actionsList: [] };
  const { handleBackNavigation, setSelectedTab, setAnchorEl } = eventHandlers ?? {};

  const theme = useTheme();

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab?.(newValue);
  };

  const handleClose = () => {
    setAnchorEl && setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <StyledMainContainer sx={{ minHeight: tabs?.length ? theme.spacing(22) : theme.spacing(16) }}>
      <StyledContainer>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {hasBackNavigation && (
            <IconButton onClick={handleBackNavigation}>
              <BackIcon />
            </IconButton>
          )}
          {loading ? (
            <StyledSkeleton variant="rounded" />
          ) : (
            <>
              <Box>
                <Typography sx={{ ml: hasBackNavigation ? '8px' : 0 }} component="h1">
                  {title}
                </Typography>
                <Typography component="p">{description}</Typography>
              </Box>

              {chipList?.map((chip, index) => {
                return (
                  <Chip
                    key={index}
                    data={{ label: chip.data.label }}
                    config={{
                      isDisabled: chip.config.isDisabled,
                      chipColor: chip.config.chipColor
                    }}
                  />
                );
              })}
            </>
          )}
        </Box>

        {actionsList && actionsList.length > 0 && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
            {actionsList.map((action, index) => {
              return (
                <Fragment key={index}>
                  <StyledButton
                    onClick={action.eventHandlers.handleClick}
                    color={action.config.color}
                    size={action.config.size}
                    variant={action.config.variant}
                    startIcon={action.config.startIcon}
                    endIcon={action.config.endIcon}
                    disabled={action.config.disabled}
                    loading={action.config.loading}
                  >
                    {action.children}
                  </StyledButton>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button'
                    }}
                  >
                    {buttonMenuItems?.map((menuItem) => {
                      return (
                        <MenuItem key={menuItem.name} onClick={menuItem.action}>
                          {menuItem.name}
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </Fragment>
              );
            })}
          </Box>
        )}
      </StyledContainer>

      {tabs?.length === 0 && !tabsLoading && <Box sx={{ height: '78px' }} />}

      {tabsLoading ? (
        <Box sx={{ display: 'flex' }}>
          <Skeleton
            variant="text"
            width={'20%'}
            height={78}
            sx={{ bgcolor: theme.palette.action.hover }}
          />
        </Box>
      ) : (
        tabs && (
          <StyledTabsContainer>
            <StyledTabs
              sx={{ marginLeft: colleace ? 0 : '32px' }}
              value={selectedTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              {tabs.map((tab) => {
                return (
                  <StyledTab
                    sx={{ ...(!colleace && { minWidth: '120px', marginRight: '40px' }) }}
                    key={tab.value}
                    value={tab.value}
                    label={tab.label}
                    disabled={tab.disabled}
                  />
                );
              })}
            </StyledTabs>
          </StyledTabsContainer>
        )
      )}
    </StyledMainContainer>
  );
};

export default PageHeader;
